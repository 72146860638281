/* You can add global styles to this file, and also import other style files */

$primary: #532a8c;

@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons";
@import "ngx-toastr/toastr";

$pink-color: #a6219d;
$purple-color: #532a8c;
$white-color: #fff;

// #FAF9F6 - offwhite color

* {
  box-sizing: border-box;
}

*:focus {
  box-shadow: none;
  outline: none;
  border: none;
}

body {
  width: 100%;
  // height: 100%;
  // background: url("./assets/static/canvas.webp");
}

.custom-btn,
.custom-btn:focus,
.custom-btn:hover,
.custom-btn::selection {
  background: $purple-color;
  border-color: $purple-color;
  color: $white-color;
  box-shadow: none;
}

.custom-outline-btn {
  border: 1px solid $purple-color;
  color: $purple-color;
  box-shadow: none;
}

.custom-outline-btn:focus,
.custom-outline-btn:hover,
.custom-outline-btn::selection {
  background: $purple-color;
  color: $white-color;
  box-shadow: none;
}

// Offcanvas Panel Class
.offcanvasbody {
  height: 100%;
  overflow-y: auto;
}

// Special Feature
.special > *,
.ssc-text-color {
  color: $purple-color;
}

// Navbar
.custom-bg-hover:hover,
.custom-bg-hover:active {
  background: $purple-color !important;
  color: #fff !important;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.is-active {
  font-weight: 600;
  color: $purple-color !important;
}

.accordion-item {
  margin: 1rem 0;
  border: 0;
  border-radius: 0;
}

.accordion-button {
  border: 0.085rem solid #eee;
  border-radius: 1rem !important;
}

.accordion-body {
  border: 1px solid #eee;
  border-top: transparent;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.accordion-button:not(.collapsed) {
  background: none;
  outline: none !important;
  box-shadow: none;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: transparent;
}

.accordion-button:active,
.accordion-button:focus,
.accordion-button:hover {
  background: none;
  outline: none !important;
  border-color: none;
  box-shadow: none;
}

.heading-font {
  font-family: "Poppins", sans-serif;
}

// Image Animation
img {
  animation: imgAnimation 1s;
}

@keyframes imgAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
